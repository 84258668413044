import "./App.css";
// importing components from react-router-dom package
import {
    Routes,
    Route,
} from "react-router-dom";

import Main from "./Main/Main";
import Advantech from "./Advantech/Advantech";

function App() {
	return (

                <Routes>
                    {/* This route is for home component 
          with exact path "/", in component props 
          we passes the imported component*/}
                    <Route
                        path="/"
                        element={<Main />}
                    />
 
                    {/* This route is for about component 
          with exact path "/about", in component 
          props we passes the imported component*/}
                    <Route
                        path="/advantech"
                        element={<Advantech />}
                    />
                </Routes>

	);
}

export default App;
