import React, { Component } from "react";
import SelectFileButton from "../SelectFileButton/SelectedFileButton";
import UploadFileButton from "../UploadFileButton/UploadFileButton";
import { render } from "template-file";
import template from "../assets/Templates/template.txt";
import { Link } from "react-router-dom";

class Main extends Component {
	state = {
		selectedFile: null,
		success: false,
		template: null,
		isReadingTemplate: true,
	};

	componentDidMount() {
		fetch(template)
			.then(function (res) {
				return res.text();
			})
			.then(
				function (data) {
					this.setState({
						template: data,
						isReadingTemplate: false,
					});
				}.bind(this),
			)
			.catch(function (err) {
				console.log(err, " error");
			});
	}

	render() {
		return (
			<div>
				<h1 className="text-3xl font-sans text-center text-indigo-600 semibold">VPN Config Utility</h1>
				<div className="grid items-center justify-center bg-grey-lighter">
					<div className="text-center">
					<ul>
                		<li>
							<Link to="/">Home</Link>
						</li>
                		<li>
							<Link to="/advantech">Advantech</Link>
						</li>
					</ul>
					</div>
				</div>
				{this.state.isReadingTemplate ? null : (
				<div className="flex w-full h-screen items-center justify-center bg-grey-lighter">
					<div className="text-center">
						<SelectFileButton addFileHandler={this.addFileHandler} />
						{this.state.success ? (
							<UploadFileButton
								classes="border border-green-500 bg-green-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-green-600 focus:outline-none focus:shadow-outline"
								text="Download"
								onClickHandler={this.downloadFileHandler}
							/>
						) : (
							<UploadFileButton
								classes="border border-indigo-500 bg-indigo-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline"
								text="Process"
								onClickHandler={this.processFileHandler.bind(this)}
							/>
						)}
					</div>
				</div>
			
		)}
		</div>
		);
	}

	addFileHandler = (event) => {
		console.log(event.target.files[0]);
		this.setState({
			selectedFile: event.target.files[0],
			loaded: 0,
		});
	};

	processFileHandler = () => {
		if (!this.state.selectedFile) {
			alert("Please select a file!");
			return;
		}

		let selectedFile = this.state.selectedFile;
		var reader = new FileReader();
		reader.onloadend = function (event) {
			//Print file output
			//console.log(event.target.result);

			//Create map of key/values of cfg file
			var map = event.target.result.split("\n").reduce((obj, line) => {
				var cols = line.split("=");
				// Tolerate empty lines. There may be one at the end of the input.
				if (cols.length >= 2) {
					//Some values need to be decoded
					switch (cols[0]) {
						case "OPENVPN_CA_CERT":
						case "OPENVPN_LOCAL_CERT":
						case "OPENVPN_LOCAL_KEY":
							let encodedValue = cols[1];
							let decodedValue = atob(encodedValue);
							cols[1] = decodedValue;
							break;
						default:
							break;
					}
					obj[cols[0]] = cols[1];
				}
				return obj;
			}, {});
			console.log(map);
			this.setState({ config: map });

			let filledTemplate = render(this.state.template, this.state.config);
			this.setState({ filledTemplate: filledTemplate });
		}.bind(this);
		reader.readAsText(selectedFile);
		this.setState({ success: true });
	};

	downloadFileHandler = () => {
		const element = document.createElement("a");
		const file = new Blob([this.state.filledTemplate], {
			type: "text/plain",
		});
		element.href = URL.createObjectURL(file);
		let fileName = "openvpn-config.ovpn";
		if (this.state.config.OPENVPN_DESCRIPTION) {
			fileName = this.state.config.OPENVPN_DESCRIPTION;
		}
		element.download = fileName + ".ovpn";
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
	};
}
export default Main;
